<template>
  <div class="relative group" v-if="postData"
    :class="[getHeightOfScreenWidth(0.75),
    {
      'cursor-pointer lg:rounded-lg': !singlePost,
      'rounded-t-lg': singlePost,
      'lg:h-second-post-img lg:max-h-second-post-img': secondPost,
      'lg:h-first-post-img lg:max-h-first-post-img': !small,
      'lg:h-post-img lg:max-h-post-img':small,
    }]"
       @click="$emit('post-clicked', postData.slug)"
       :style="[{background: 'url(' + getImage + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}]">
    <div v-if="!emptyPost" class="flex flex-col justify-end h-full lg:rounded-t-lg"
      :class="[{'lg:rounded-b-lg': !singlePost}]"
    >
      <div class="p-3">
        <div class="flex flex-col px-3 pt-3 pb-2 bg-white w-full border border-white opacity-95 rounded-lg">
          <div class="space-y-2">
            <div class="text-black font-semibold leading-tight"
              :class="[transformScale, {'text-base':small, 'text-lg':!small}]"
            >
              {{ small?excerpt(postData.title,85):postData.title }}
            </div>
            <div v-if="!small" class="text-grayedDark leading-ug "
              :class="[transformScale,{'hidden': singlePost,'text-sm':small,'text-base':!small }]"
            >
              <span v-html="postData.excerpt">
              </span>
            </div>
          </div>
          <hr class="border-grayedDark border-dashed -mx-3 my-2" :class="[{
            'hidden': singlePost
          }]"/>
          <div class="text-sm flex flex-row text-grayed justify-end"
            :class="[transformScale, {
              'hidden': singlePost,
              'text-s':!small,
              'text-xs':small,
            }]"
            v-if="!emptyPost"
          >
            <span v-if="!narrow && !small" class="text-black hidden lg:block">{{ postData.authorName }}</span>
            <span v-if="!narrow && !small" class="hidden lg:block">&nbsp;|&nbsp;</span><span class="text-redAccent"
                  :class="{'hover:opacity-50': !isCurrentRoute(postData.postCategorySlug)}"
                  @click.stop="!isCurrentRoute(postData.postCategorySlug) ? goToCategory(postData.postCategorySlug) : null">
              {{ trans(`POST_CATEGORY:${postData.postCategorySlug}`) }}
            </span>
              &nbsp;|&nbsp;<span class="text-black">{{ postData.publishedAt }}</span>
          </div>
        </div>
      </div>
      <hr class="border-dashed border-grayed" :class="[{'hidden': !singlePost}]"/>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import {excerpt} from "@/common/helpers/utils";
import {getHeightOfScreenWidth} from "@/common/helpers/utils";

export default {
  name: "PostCard",
  props: {
    postData: Object,
    narrow: Boolean,
    small: Boolean,
    singlePost: Boolean,
    secondPost:Boolean,
	  emptyPost: Boolean,
  },
  data() {
    return {
      excerpt:excerpt,
      getHeightOfScreenWidth: getHeightOfScreenWidth,
    }
  },
	computed: {
		getImage() {
			if(this.singlePost) {
				return this.postData.image;
			}
			return this.postData.thumbnailImage
		},

		// nie wiem do czego to ma służyć więc zostawiam czyste computed żeby błedu nie waliło, można tu dodać jakieś klasy
		transformScale() {
			return '';
		}
	},
  methods: {
    goToCategory(slug) {
      router.push({name: 'posts', params: {postCategory: slug}});
    },
    goToPost(postCategorySlug, postSlug) {
      router.push({name: 'post-show', params: {postCategory: postCategorySlug, slug: postSlug}})
    },
    isCurrentRoute(slug) {
      return this.$route.params.postCategory === slug;
    },
  }
}
</script>

<style scoped>
  .bg-first-post {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 54%);
  }
</style>
