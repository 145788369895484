var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.postData)?_c('div',{staticClass:"relative group",class:[_vm.getHeightOfScreenWidth(0.75),
  {
    'cursor-pointer lg:rounded-lg': !_vm.singlePost,
    'rounded-t-lg': _vm.singlePost,
    'lg:h-second-post-img lg:max-h-second-post-img': _vm.secondPost,
    'lg:h-first-post-img lg:max-h-first-post-img': !_vm.small,
    'lg:h-post-img lg:max-h-post-img':_vm.small,
  }],style:([{background: 'url(' + _vm.getImage + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}]),on:{"click":function($event){return _vm.$emit('post-clicked', _vm.postData.slug)}}},[(!_vm.emptyPost)?_c('div',{staticClass:"flex flex-col justify-end h-full lg:rounded-t-lg",class:[{'lg:rounded-b-lg': !_vm.singlePost}]},[_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"flex flex-col px-3 pt-3 pb-2 bg-white w-full border border-white opacity-95 rounded-lg"},[_c('div',{staticClass:"space-y-2"},[_c('div',{staticClass:"text-black font-semibold leading-tight",class:[_vm.transformScale, {'text-base':_vm.small, 'text-lg':!_vm.small}]},[_vm._v(" "+_vm._s(_vm.small?_vm.excerpt(_vm.postData.title,85):_vm.postData.title)+" ")]),(!_vm.small)?_c('div',{staticClass:"text-grayedDark leading-ug ",class:[_vm.transformScale,{'hidden': _vm.singlePost,'text-sm':_vm.small,'text-base':!_vm.small }]},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.postData.excerpt)}})]):_vm._e()]),_c('hr',{staticClass:"border-grayedDark border-dashed -mx-3 my-2",class:[{
          'hidden': _vm.singlePost
        }]}),(!_vm.emptyPost)?_c('div',{staticClass:"text-sm flex flex-row text-grayed justify-end",class:[_vm.transformScale, {
            'hidden': _vm.singlePost,
            'text-s':!_vm.small,
            'text-xs':_vm.small,
          }]},[(!_vm.narrow && !_vm.small)?_c('span',{staticClass:"text-black hidden lg:block"},[_vm._v(_vm._s(_vm.postData.authorName))]):_vm._e(),(!_vm.narrow && !_vm.small)?_c('span',{staticClass:"hidden lg:block"},[_vm._v(" | ")]):_vm._e(),_c('span',{staticClass:"text-redAccent",class:{'hover:opacity-50': !_vm.isCurrentRoute(_vm.postData.postCategorySlug)},on:{"click":function($event){$event.stopPropagation();!_vm.isCurrentRoute(_vm.postData.postCategorySlug) ? _vm.goToCategory(_vm.postData.postCategorySlug) : null}}},[_vm._v(" "+_vm._s(_vm.trans(("POST_CATEGORY:" + (_vm.postData.postCategorySlug))))+" ")]),_vm._v("  | "),_c('span',{staticClass:"text-black"},[_vm._v(_vm._s(_vm.postData.publishedAt))])]):_vm._e()])]),_c('hr',{staticClass:"border-dashed border-grayed",class:[{'hidden': !_vm.singlePost}]})]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }